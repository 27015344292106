'use client';

import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { useTranslations } from 'next-intl';
export default function NotFound() {
  const t = useTranslations('NotFoundPage');
  useEffect(() => {
    document.body.style.backgroundColor = '#f0f0f0';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  return <div className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-800" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <div className="text-center space-y-6">
        <motion.div className="text-9xl font-bold relative" initial={{
        opacity: 0,
        y: -20
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="not-found.tsx">
          <span className="absolute top-0 left-0 -ml-1 text-gray-300 select-none">404</span>
          <span className="relative z-10 text-gray-800">404</span>
          <span className="absolute top-0 left-0 ml-1 text-red-500 select-none animate-pulse">404</span>
        </motion.div>

        <motion.h2 className="text-2xl font-semibold" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} transition={{
        delay: 0.2,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="not-found.tsx">
          {t('title')}
        </motion.h2>

        <motion.p className="text-gray-600 max-w-md mx-auto" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} transition={{
        delay: 0.4,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="not-found.tsx">
          {t('message')}
        </motion.p>

        <motion.div initial={{
        opacity: 0,
        y: 20
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        delay: 0.6,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="not-found.tsx">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">
            <Button data-sentry-element="Button" data-sentry-source-file="not-found.tsx">{t('goHome')}</Button>
          </Link>
        </motion.div>
      </div>
    </div>;
}